import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Card, Img, QLazyDisp, Tag } from "../components/utilityComps.js";
import { InpAutoWrap, set_state_ob } from "../components/input.js";
import {
  base64_to_json,
  dec,
  emp,
  from_time_mini,
  getv,
  iso,
  iso_format,
  json_to_base64,
  jstr,
  nano,
  nils,
  time_diff_txt,
  to_time_mini,
} from "../utils/utils.js";
import _ from "lodash";
import { useAppContext } from "../App.js";
import {
  class_cn,
  class_text,
  get_payout_cn,
  get_payout_txt,
  tablecn,
} from "../utils/cn_map.js";
import {
  gen_filter_state_ob,
  gen_filters_from_valob,
  gen_valob_from_filters,
} from "../utils/filt.js";
import {
  BY_Star,
  PosTag,
  fee_tag_cn,
  fee_tag_txt,
} from "../utils/raceutils.js";
import { useQueries } from "react-query";
import {
  polytxnlink,
  polytxnlink_sp,
  q_admin_races,
  q_admin_rcounts,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { metamaskimg, polychainimg, wethimg } from "../utils/links.js";

const RaceCountsContext = createContext();
export const useRaceCountsContext = () => useContext(RaceCountsContext);

const racecn = {
  mainfiltbtn:
    "transition duration-500 resp-text--2 font-digi font-semibold  text-center mb-3 ",
};

const status_cn = {
  finished: "bg-green-400 text-black",
  cancelled: "bg-red-400 text-black",
  open: "bg-blue-400 text-black",
  scheduled: "bg-yellow-400 text-black",
};

const FiltSection = () => {
  const rccon = useRaceCountsContext();
  const { filt, set_filt, clear_filt, submit } = rccon;
  const inpargs = {
    fkey: "open",
    filters: filt,
    set_filters: set_filt,
  };

  return (
    <Card className={"w-reg w-full"}>
      <div className="grid sm:grid-cols-2 md:sm:grid-cols-2">
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">CB</p>
          <InpAutoWrap {...{ ...inpargs, idd: "cb" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Class</p>
          <InpAutoWrap {...{ ...inpargs, idd: "class" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Gates</p>
          <InpAutoWrap {...{ ...inpargs, idd: "rgate" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Payout</p>
          <InpAutoWrap {...{ ...inpargs, idd: "payout" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Formats</p>
          <InpAutoWrap {...{ ...inpargs, idd: "format" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Fee</p>
          <InpAutoWrap {...{ ...inpargs, idd: "feetag" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">
            is Chain
          </p>
          <InpAutoWrap {...{ ...inpargs, idd: "is_chain" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Status</p>
          <InpAutoWrap {...{ ...inpargs, idd: "status" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">Created</p>
          <InpAutoWrap {...{ ...inpargs, idd: "create_time" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">
            PresetID
          </p>
          <InpAutoWrap {...{ ...inpargs, idd: "presetid" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">RaceID</p>
          <InpAutoWrap {...{ ...inpargs, idd: "rid" }} />
        </div>
        <div className="col-span-2 fr-sc">
          <p className="font-digi text-slate-500 w-[8rem] text-left">
            race_name
          </p>
          <InpAutoWrap {...{ ...inpargs, idd: "race_name" }} />
        </div>

        <div className="col-span-2 fr-sc">
          <div className="flex-1"></div>
          {[
            ["5m", [5, "minutes"]],
            ["1hr", [1, "hour"]],
            ["24hrs", [24, "hours"]],
            ["7days", [7, "days"]],
            ["1month", [1, "month"]],
            ["all", []],
          ].map(([k, dur]) => {
            return (
              <Tag
                onClick={() => {
                  let st = moment()
                    .subtract(...dur)
                    .toISOString();
                  let ed = iso();
                  let newob = k == "all" ? {} : { mi: st, mx: ed };
                  set_state_ob(filt, set_filt, "create_time.vals", newob);
                }}
                className={twMerge(
                  racecn.mainfiltbtn,
                  "text-acc0 border border-acc0",
                )}
              >
                {_.upperCase(k)}
              </Tag>
            );
          })}
        </div>
      </div>
      <div className="fr-sc my-2">
        <Tag
          onClick={clear_filt}
          className="resp-text--3 text-red-400 border border-red-400"
        >
          {"Clear Filters"}
        </Tag>
        <div className="flex-1"></div>
        <Tag onClick={submit} className="resp-text--3 bg-acc0 text-black px-4">
          {"Submit"}
        </Tag>
      </div>
    </Card>
  );
};

const AttemptLogCard = ({ t, vau, vau_name, hdets }) => {
  return (
    <Card className={"p-2 w-full"}>
      <div className="fr-ss gap-1 my-1">
        <span>{iso_format(t.time)}</span>
        <div className="flex-1"></div>
        <span>{from_time_mini(t.time)}</span>
      </div>
      <div className="fr-sc gap-1 my-1">
        <div className="fc-ss">
          <span className="text-acc0 text-[12px]">{vau}</span>
          <span className="text-white">
            {">> "}
            {vau_name}
          </span>
        </div>
      </div>
      <div className="fr-sc gap-2 my-2">
        <span className="text-acc0 border-acc0 p-1">{t.rid}</span>
        <span className="text-acc0 font-mono">{t.hid}</span>
        <span>-</span>
        <span className="italic">{hdets.name}</span>
      </div>
      {!nils(t.hash) && (
        <Link to={polytxnlink(t.hash)}>
          <div className="fr-sc gap-2 my-2 text-orange-300 font-mono">
            <Img img={metamaskimg} className={"w-[1.5rem]"} />
            <span className="font-mono">{t.hash.slice(0, 5)}...</span>
          </div>
        </Link>
      )}
    </Card>
  );
};

const LedgerTxCard = ({ t, vau, vau_name, hdets }) => {
  return (
    <Card className={"p-2 w-full"}>
      <div className="fr-sc gap-2 my-1">
        <Link to={polytxnlink_sp(t.id)}>
          <div className="fr-sc gap-2">
            <Img className={"w-[2rem]"} img={polychainimg} />
            <span className="text-purple-400 font-mono">
              {t.id.slice(0, 5)}...
            </span>
          </div>
        </Link>
        <div className="flex-1">
          <Cost amt={t.amt} />
        </div>
      </div>
      <div className="fr-ss gap-1 my-1">
        <span>{iso_format(t.date)}</span>
        <div className="flex-1"></div>
        <span>{from_time_mini(t.date)}</span>
      </div>
      <div className="fr-sc gap-1 my-1">
        <div className="fc-ss">
          <span className="text-acc0 text-[12px]">{vau}</span>
          <span className="text-white">
            {">> "}
            {vau_name}
          </span>
        </div>
      </div>
      <div className="fr-sc gap-2 my-2">
        <span className="text-acc0 border-acc0 p-1">{t.rid}</span>
        <span className="text-acc0 font-mono">{t.hid}</span>
        <span>-</span>
        <span className="italic">{hdets.name}</span>
      </div>
    </Card>
  );
};

const Cost = ({ amt }) => {
  return (
    <div className="fr-cc gap-1">
      <Img className={"w-[1.5rem]"} img={wethimg} />
      <span>{dec(amt, 6)}</span>
    </div>
  );
};

const CountsTable = () => {
  const rccon = useRaceCountsContext();
  const { counts } = rccon;
  const statuses = ["open", "scheduled", "finished", "cancelled", "tot"];
  return (
    <Card className={"w-full"}>
      <table className={tablecn.table}>
        <tbody>
          <tr className={tablecn.tr}>
            {statuses.map((status) => (
              <td className={tablecn.td}>
                <span className="w-[5rem] text-center">
                  {_.upperCase(status)}
                </span>
              </td>
            ))}
          </tr>
          <tr className={tablecn.tr}>
            {statuses.map((status) => (
              <td className={tablecn.td}>
                <span className="w-[5rem] text-center">
                  {counts[status] ?? "--"}
                </span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

const RaceCard = ({ race: r, hsmap, vsmap }) => {
  let result = _.keyBy(r.results, "hid") || {};

  let entry_txns = _.filter(r.ledger_txns, (e) => e.type == "race_entry");
  let payout_txns = _.filter(r.ledger_txns, (e) => e.type == "race_payout");
  let refund_txns = _.filter(r.ledger_txns, (e) => e.type == "race_refund");

  let bef_log = _.filter(r.logqueue, (e) => e.type == "before_cont");
  let aft_log = _.filter(r.logqueue, (e) => e.type == "after_cont");

  return (
    <Card className={"w-full bg-dark m-2"}>
      <div className="grid grid-cols-6 gap-2">
        <Link target="_blank" to={`https://fbike.dnaracing.run/race/${r.rid}`}>
          <Tag className="border border-acc0 text-acc0">{r.rid}</Tag>
        </Link>
        <div className="col-span-2">
          <div className="fr-sc gap-2">
            <Tag className={class_cn(r.class)}>C{r.class}</Tag>
            <Tag className={"text-acc0 font-digi italic"}>CB{r.cb}00</Tag>
            <span className="font-digi">{r.race_name}</span>
          </div>
        </div>
        <div className="fc-ss gap-1">
          <span>Created</span>
          <span>{nils(r.create_time) ? "--" : iso_format(r.create_time)}</span>
          <span>
            {nils(r.create_time) ? "--" : from_time_mini(r.create_time)}
          </span>
        </div>
        <div className="fc-ss gap-1">
          <span>Started</span>
          <span>{nils(r.start_time) ? "--" : iso_format(r.start_time)}</span>
          <span>
            {nils(r.start_time) ? "--" : from_time_mini(r.start_time)}
          </span>
        </div>
        <div className="fc-ss gap-1">
          <span>Ended</span>
          <span>{nils(r.end_time) ? "--" : iso_format(r.end_time)}</span>
          <span>{nils(r.end_time) ? "--" : from_time_mini(r.end_time)}</span>
        </div>

        <Tag className={status_cn[r.status]}>
          <span className="font-digi">{r.status}</span>
        </Tag>
        <Tag>
          <span className="font-digi text-acc0">{_.capitalize(r.format)}</span>
        </Tag>
        <Tag className="fr-cc text-yellow-300 gap-2 font-digi text-[1.2rem]">
          <span>{(r.hids || []).length}</span>
          <span>/</span>
          <span>{_.capitalize(r.rgate)}</span>
          <span>Gates</span>
        </Tag>
        <Tag></Tag>
        <div className="text-yellow-400 fr-sc gap-1">
          Preset:
          <Tag className="text-yellow-400 border border-yellow-400 w-full">
            {r.presetid}
          </Tag>
        </div>
      </div>
      <p className="mt-1 px-2 italic text-[1.5rem] font-digi">Bikes</p>
      <div className="grid grid-cols-3 gap-2">
        {r.hids.map((hid) => {
          let hdets = hsmap[hid];
          let vau = r.racing_vaults[hid];
          let vau_name = vsmap[vau];
          let hres = result[hid] || {};
          return (
            <Card className={"p-2 w-full"}>
              <div className="fr-sc gap-1 my-1">
                <span className="text-acc0 font-mono">{hid}</span>
                <span>-</span>
                <span>{hdets.name}</span>
              </div>
              <div className="fr-sc gap-1 my-1">
                <BY_Star star={hres.star} />
                {!nils(hres.pos) && (
                  <PosTag
                    className={"font-regular py-0.5 px-1"}
                    pos={hres.pos}
                  />
                )}
                {!nils(hres.time) && <span>{dec(hres.time, 3)}sec</span>}
                <div className="flex-1"></div>
                <span>F{hdets.fno}</span>
                <span>/</span>
                <span>{_.capitalize(hdets.type)}</span>
              </div>
              <div className="fr-sc gap-1 my-1">
                <div className="fc-ss">
                  <span className="text-acc0 text-[12px]">{vau}</span>
                  <span className="text-white">
                    {">> "}
                    {vau_name}
                  </span>
                </div>
              </div>
            </Card>
          );
        })}
      </div>

      <div className="fr-sc gap-2">
        <p className="mt-1 px-2 italic text-[1.5rem] font-digi">
          Ledger Entry Txns
        </p>
        <span className="italic text-acc0">{entry_txns.length} Txns</span>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {entry_txns.map((t) => {
          let vau = t.from;
          let vau_name = vsmap[vau];
          let hdets = hsmap[t.hid];
          return <LedgerTxCard {...{ key: t.id, t, vau, vau_name, hdets }} />;
        })}
      </div>

      <div className="fr-sc gap-2">
        <p className="mt-1 px-2 italic text-[1.5rem] font-digi">
          Ledger Payout Txns
        </p>
        <span className="italic text-acc0">{payout_txns.length} Txns</span>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {payout_txns.map((t) => {
          let vau = t.to;
          let vau_name = vsmap[vau];
          let hdets = hsmap[t.hid];
          return <LedgerTxCard {...{ key: t.id, t, vau, vau_name, hdets }} />;
        })}
      </div>

      <div className="fr-sc gap-2">
        <p className="mt-1 px-2 italic text-[1.5rem] font-digi">
          Ledger Refund Txns
        </p>
        <span className="italic text-acc0">{refund_txns.length} Txns</span>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {refund_txns.map((t) => {
          let vau = t.to;
          let vau_name = vsmap[vau];
          let hdets = hsmap[t.hid];
          return <LedgerTxCard {...{ key: t.id, t, vau, vau_name, hdets }} />;
        })}
      </div>

      <div className="fr-sc gap-2">
        <p className="mt-1 px-2 italic text-[1.5rem] font-digi">
          Attempt Log - Before_Cont
        </p>
        <span className="italic text-acc0">{bef_log.length} Txns</span>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {bef_log.map((t) => {
          let vau = t.vault;
          let vau_name = vsmap[vau];
          let hdets = hsmap[t.hid];
          return <AttemptLogCard {...{ key: t.id, t, vau, vau_name, hdets }} />;
        })}
      </div>

      <div className="fr-sc gap-2">
        <p className="mt-1 px-2 italic text-[1.5rem] font-digi">
          Attempt Log - After_Cont
        </p>
        <span className="italic text-acc0">{aft_log.length} Txns</span>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {aft_log.map((t) => {
          let vau = t.vault;
          let vau_name = vsmap[vau];
          let hdets = hsmap[t.hid];
          return <AttemptLogCard {...{ key: t.id, t, vau, vau_name, hdets }} />;
        })}
      </div>
    </Card>
  );
};

const RacesTable = () => {
  const rccon = useRaceCountsContext();
  const { racesmap } = rccon;
  const { races, hsmap, vsmap } = racesmap;
  return (
    <Card className={"w-full"}>
      {races.map((r) => {
        return <RaceCard key={r.rid} {...{ race: r, hsmap, vsmap }} />;
      })}
    </Card>
  );
};

const RaceCounts = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;
  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = f ?? {};
    return f;
  }, [psearch]);

  const [filt, set_filt] = useState({
    class: {
      type: "options",
      path: "class",
      cfilter: true,
      options: [1, 2, 3, 4, 5, 50, 60, 70, 80, 90],
      vals: basefilt?.class,
      txt_fn: (o) => {
        if (o == "my_races") return `My Races`;
        if (o == "_1v1") return `1v1`;
        return class_text(o, "T");
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return class_cn(o, "font-digi bg-opacity-80");
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-${class_cn(op)?.replace("bg-", "")}`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    cb: {
      type: "options",
      path: "cb",
      cfilter: true,
      options: [10, 12, 14, 16, 18, 20, 22],
      filter_exception: [],
      vals: basefilt?.cb,
      txt_fn: (o) => {
        return `CB${o}00`;
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    payout: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.payout.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "Payout",
      show_label: false,
      options: [
        "na",
        "1v1",
        "wta",
        "top2",
        "even2",
        "top3",
        "top4",
        "dblup",
        // "dblup6",
        // "dblup12",
        "pity",
        // "pity6",
        // "pity12",
        "hybrid",
        "heavy",
        "variance",
      ],
      filter_exception: [],
      vals: basefilt?.payout,
      txt_fn: (o) => {
        return get_payout_txt(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return get_payout_cn(o);
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    format: {
      type: "options",
      path: (r, f, filters) => {
        if (_.isEmpty(f)) return true;
        let c = 0;
        for (let p of f) {
          if (r.format.startsWith(p)) c++;
        }
        return c !== 0;
      },
      cfilter: true,
      label: "format",
      show_label: false,
      options: [
        "normal",
        "reduced",
        "spin_n_go",
        "sit_n_go_subround",
        "rounds",
        "sub_rounds",
      ],
      filter_exception: [],
      vals: _.isEmpty(basefilt?.format) ? ["normal"] : basefilt?.format,
      txt_fn: (o) => {
        return _.upperCase(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem]`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    rgate: {
      type: "options",
      path: "rgate",
      cfilter: true,
      label: "Race Gates",
      show_label: false,
      options: [2, 3, 4, 6, 8, 10, 16],
      filter_exception: [],
      vals: basefilt?.rgate?.map((e) => parseInt(e)),
      txt_fn: (o) => {
        return `${o} Gates`;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[6rem]`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    is_chain: {
      type: "options",
      path: "is_chain",
      cfilter: true,
      label: "is_chain",
      show_label: false,
      options: [true, false],
      filter_exception: [],
      vals: basefilt?.is_chain?.map((e) => e === true || e === "true"),
      txt_fn: (o) => {
        return `${o}`;
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[6rem]`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    feetag: {
      type: "options",
      path: "feetag",
      cfilter: true,
      label: "Race Gates",
      show_label: false,
      options: ["X", "A", "B", "C", "D", "E", "E75", "E50", "F"],
      filter_exception: [],
      vals: basefilt?.feetag?.map((e) => parseInt(e)),
      txt_fn: (o) => {
        return fee_tag_txt(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[6rem]`,
      color_fn: (o) => {
        return "bg-acc0/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-acc0`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    status: {
      type: "options",
      path: "status",
      cfilter: true,
      label: "Race Gates",
      show_label: false,
      options: ["finished", "cancelled", "open", "scheduled"],
      filter_exception: [],
      vals: basefilt?.status,
      txt_fn: (o) => {
        return _.capitalize(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[6rem]`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    create_time: {
      type: "date-range",
      path: "create_time",
      cfilter: true,
      label: "create_time",
      show_label: false,
      filter_exception: [],
      vals: basefilt?.create_time ?? {},
      txt_fn: (o) => {
        return _.capitalize(o);
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[6rem]`,
      color_fn: (o) => {
        return "bg-purple-500/50";
      },
      active_cn: (active, op) => {
        if (!active) return "bg-dark";
        let shadow = `shadow-lg shadow-purple-500`;
        let transform = "transform -skew-x-12";
        return `${transform} ${shadow}`;
      },
    },
    rid: {
      type: "inputtext",
      path: "rid",
      vals: basefilt?.rid || null,
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
    },
    presetid: {
      type: "inputtext",
      path: "presetid",
      vals: basefilt?.presetid || null,
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
    },
    race_name: {
      type: "inputtext",
      path: "race_name",
      vals: basefilt?.race_name || null,
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
    },
  });

  const valfilt = useMemo(() => {
    let valfilt = gen_valob_from_filters(filt);
    return valfilt;
  }, [filt]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const rem = useMemo(() => {
    let rem = {};
    rem.f = json_to_base64(valfilt);
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt)]);

  const [reqfilt, set_reqfilt] = useState(basefilt);
  const submit = () => {
    set_reqfilt(valfilt);
  };

  const [page, set_page] = useState(0);
  const limit = 10;

  const [qocounts, qoraces] = useQueries([
    q_admin_rcounts({ filt: reqfilt }, { enabled: !nils(reqfilt) }),
    q_admin_races({ filt: reqfilt, page, limit }, { enabled: !nils(reqfilt) }),
  ]);
  const counts = useMemo(() => {
    if (qissuccesss(qocounts)) return getv(qocounts, "data.result.counts");
    else return null;
  });
  const racesmap = useMemo(() => {
    if (qissuccesss(qoraces)) return getv(qoraces, "data.result");
    else return null;
  });

  const rccon = {
    filt,
    set_filt,
    valfilt,
    clear_filt,
    submit,

    qocounts,
    counts,

    qoraces,
    racesmap,
  };

  return (
    <RaceCountsContext.Provider value={rccon}>
      <div className="h-page">
        <div className="h-[2rem]"></div>
        <div className="w-[80rem] mx-auto">
          <FiltSection />
          <QLazyDisp qo={qocounts} data={counts}>
            <CountsTable />
          </QLazyDisp>
          <Card className={"w-full"}>
            <div className="fr-sc gap-2">
              <div className="flex-1"></div>
              <span>Page-{page}</span>
              <Tag
                onClick={() => set_page(Math.min(0, page - 1))}
                className={twMerge("fc-cc w-[2rem]")}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Tag>
              <Tag
                onClick={() => set_page(Math.max(0, page + 1))}
                className={twMerge("fc-cc w-[2rem]")}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </Card>
          <QLazyDisp qo={qoraces} data={racesmap}>
            <RacesTable />
          </QLazyDisp>
        </div>
      </div>
    </RaceCountsContext.Provider>
  );
};

export default RaceCounts;
