import _ from "lodash";
import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import { useQueries } from "react-query";
import { useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import { extract_inp } from "../components/input";
import { Card, InpText, Tag } from "../components/utilityComps";
import {
  qiserr,
  qissuccess,
  q_admin_basestats_get,
  q_admin_basestats_set,
} from "../queries/queries";
import {
  getv,
  nils,
  dec,
  json_to_base64,
  base64_to_json,
  jstr,
  cdelay,
} from "../utils/utils";
import { set_state_ob } from "../components/input";
import { ErrorBoundary } from "../components/errbou";

const rvmodes = ["car", "bike", "horse"];

const BaseStatsContext = createContext();
export const useBaseStatsContext = () => useContext(BaseStatsContext);

const ShowStats = ({ hid }) => {
  const bcon = useBaseStatsContext();
  const { d, hids, qostats } = bcon;
  const h = getv(d, `hmap.${hid}`, {});
  const [edit, set_edit] = useState(false);
  const [so, set_so] = useState({});
  useEffect(() => {
    let o = {};
    rvmodes.forEach((rvmode) => {
      let s = getv(d, `stats.${rvmode}.${hid}`) ?? {};
      s = _.cloneDeep(s);
      delete s.hid;
      o[rvmode] = s;
    });
    set_so(o);
  }, [jstr(d), hid]);

  const update = async () => {
    set_edit(false);
    let doc = _.cloneDeep({
      hid,
      stats: so,
    });
    console.log(doc);
    let resp = await q_admin_basestats_set(doc).queryFn();
    console.log(resp);
    await cdelay(1 * 1e3);
    qostats.refetch();
  };

  return (
    <React.Fragment key={hid}>
      {rvmodes.map((rvmode, rvi) => {
        let s = getv(d, `stats.${rvmode}.${hid}`, {}) ?? {};
        return (
          <tr>
            <td>
              {rvi == 0 ? (
                <span>
                  #{hid}-{h?.name ?? "--"}
                </span>
              ) : rvi == 1 ? (
                <>
                  <div class="fr-sc resp-gap-2">
                    <div class="flex-1"></div>
                    {edit ? (
                      <>
                        <Tag
                          onClick={() => {
                            set_edit(false);
                          }}
                          className="bg-red-400 text-black"
                        >
                          <span class="resp-text--1">Cancel</span>
                        </Tag>

                        <Tag
                          onClick={() => {
                            update();
                          }}
                          className="bg-green-400 text-black"
                        >
                          <span class="resp-text--1">Update</span>
                        </Tag>
                      </>
                    ) : (
                      <Tag
                        onClick={() => {
                          set_edit(true);
                        }}
                        className="bg-yellow-400 text-black"
                      >
                        <span class="resp-text--1">Edit</span>
                      </Tag>
                    )}
                  </div>
                </>
              ) : null}
            </td>

            <td>
              <span className="font-mono"> {rvmode} </span>
            </td>
            {edit ? (
              <>
                <td>
                  <InpText
                    {...{
                      id: `basestats-${hid}-${rvmode}-dp`,
                      placeholder: "dp",
                      contprops: { className: "w-[6.5rem]" },
                      inpprops: { className: "w-full" },
                      def_val: dec(s.dp, 2),
                      setter: (v) => {
                        set_state_ob(so, set_so, `${rvmode}.dp`, parseFloat(v));
                      },
                    }}
                  />
                </td>
                <td>
                  <InpText
                    {...{
                      id: `basestats-${hid}-${rvmode}-mean1600`,
                      placeholder: "mean1600",
                      contprops: { className: "w-[6.5rem]" },
                      inpprops: { className: "w-full" },
                      def_val: dec(s.mean1600, 2),
                      setter: (v) => {
                        set_state_ob(
                          so,
                          set_so,
                          `${rvmode}.mean1600`,
                          parseFloat(v),
                        );
                      },
                    }}
                  />
                </td>
                <td>
                  <InpText
                    {...{
                      id: `basestats-${hid}-${rvmode}-stdev`,
                      placeholder: "stdev",
                      contprops: { className: "w-[6.5rem]" },
                      inpprops: { className: "w-full" },
                      def_val: dec(s.stdev, 3),
                      setter: (v) => {
                        set_state_ob(
                          so,
                          set_so,
                          `${rvmode}.stdev`,
                          parseFloat(v),
                        );
                      },
                    }}
                  />
                </td>
              </>
            ) : (
              <>
                <td>
                  <span className="font-mono">{dec(s.dp, 2)}</span>
                </td>
                <td>
                  <span className="font-mono">{dec(s.mean1600, 2)}</span>
                </td>
                <td>
                  <span className="font-mono">{dec(s.stdev, 3)}</span>
                </td>
              </>
            )}
          </tr>
        );
      })}
    </React.Fragment>
  );
};

export const BaseStatsPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const parse = (val) => {
    if (nils(val)) return [];

    val = val
      .split(/[\s,]+/g)
      .map((e) => parseInt(e))
      .filter((e) => !nils(e));
    return val;
  };

  const [hids, set_hids] = useState(base64_to_json(psearch?.hids ?? ""));
  useEffect(() => {
    upd_psearch({ hids: json_to_base64(hids) });
  }, [hids]);
  const [qostats] = useQueries([
    q_admin_basestats_get({ hids }, { enabled: !_.isEmpty(hids) }),
  ]);
  const d = useMemo(() => {
    if (qissuccess(qostats)) {
      let d = getv(qostats, "data.result") || {};
      return d;
    }
    return {};
  }, [qostats.dataUpdatedAt]);

  const submit = () => {
    try {
      let val = extract_inp("basestats-hids");
      set_hids(parse(val));
    } catch (err) {
      set_hids([]);
    }
  };
  const bcon = {
    hids,
    submit,
    d,
    hids,
    qostats,
  };

  return (
    <div class="h-page">
      <BaseStatsContext.Provider value={bcon}>
        <div class="w-[60rem] max-w-[98vw] mx-auto">
          <div class="h-[5remj]"></div>
          <Card className="bg-reg w-full">
            <p class="resp-text-1 font-digi text-acc0">BaseStats</p>
            <textarea
              id="basestats-hids"
              className="w-full p-2 outline-none border border-acc0 rounded-md bg-black h-[10rem]"
            ></textarea>
            <div class="fr-sc resp-gap-2 my-2">
              <ErrorBoundary>
                <p className="resp-text-0">
                  <span className="text-acc0">{" Hids: "}</span>
                  {(hids || []).join(", ")}
                </p>
              </ErrorBoundary>
              <div class="flex-1"></div>
              <Tag
                onClick={submit}
                className="bg-acc0/40 -skew-x-12 font-digi resp-text--1"
              >
                <span class="resp-text--1">Submit</span>
              </Tag>
            </div>
            {qostats.isLoading ? (
              <Loader01c size="s" />
            ) : qissuccess(qostats) ? (
              <>
                <table className="w-full thintdrowp4-table">
                  <thead>
                    <tr>
                      <td>Bike</td>
                      <td>RVMode</td>
                      <td>DP</td>
                      <td>Mean</td>
                      <td>SD</td>
                    </tr>
                  </thead>
                  <tbody>
                    {hids.map((hid) => {
                      return (
                        <ShowStats
                          {...{
                            hid,
                            key: hid,
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : qiserr(qostats) ? (
              <p className="text-center resp-text--1 text-red-400">
                {qiserr(qostats)}
              </p>
            ) : null}
          </Card>
        </div>
      </BaseStatsContext.Provider>
    </div>
  );
};
